@mixin rounded($radius:4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
$foreground: #A9B7C6;
$background: #2B2B2B;
$selectionForeground: #A9B7C6;
$selectionBackground: #214283;
$operators: #A9B7C6;
$strings: #A5C25C;
$names: #88be05;
$keywords: #CB772F;
$varAndProp: #A9B7C6;
$numbers: #6897BB;
$tag: #f1c829;
$attributes: #9876AA;
$comments: #75715e;
$linenos: #A9B7C6;
.highlight {
  margin-bottom: 1.5em;
  color: $foreground;
  background-color: $background;
  @include rounded(4px);
  pre {
    position: relative;
    margin: 0;
    padding: 1em;
    overflow-x: auto;
    word-wrap: normal;
    border: none;
    white-space: pre;
    background-color: #333;
    color: #ddd !important;
    code {
      white-space: pre;
      color: #ddd;
    }
  }
  pre::-webkit-scrollbar {
    height: 10px;
    background-color: #34362e;
    border-radius: 0 0 4px 4px;
  }
  pre::-webkit-scrollbar-thumb {
    background-color: #555;
    @include rounded(4px);
  }
  ::selection {
    background-color: $selectionBackground;
    color: $selectionForeground;
  }
}
.highlight > pre {
  -webkit-box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.highlight,
img,
p > nobr > code,
li > code,
h5 > code,
p > pre,
p > code,
.note > code {
  color: tomato;
  background-color: #333;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 10px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.1), 0 -1px 0 rgba(0, 0, 0, 0.5);
}


/* line numbers */
.lineno{ border-right: dashed 1px $linenos !important; color: $linenos; padding-right: 5px; padding-left: 0 !important;}

.hll { background-color: #49483e }
.c { color: $comments } /* Comment */
.err { color: #960050; background-color: #1e0010 } /* Error */
.k { color: $keywords } /* Keyword */
.l { color: $numbers} /* Literal */
.n { color: $varAndProp } /* Name */
.o { color: $operators } /* Operator */
.p { color: $varAndProp } /* Punctuation */
.cm { color: #75715e } /* Comment.Multiline */
.cp { color: #75715e } /* Comment.Preproc */
.c1 { color: #75715e } /* Comment.Single */
.cs { color: #75715e } /* Comment.Special */
.ge { font-style: italic } /* Generic.Emph */
.gs { font-weight: bold } /* Generic.Strong */
.kc { color: $keywords } /* Keyword.Constant */
.kd { color: $keywords } /* Keyword.Declaration */
.kn { color: $operators } /* Keyword.Namespace */
.kp { color: $keywords } /* Keyword.Pseudo */
.kr { color: $keywords } /* Keyword.Reserved */
.kt { color: $keywords } /* Keyword.Type */
.ld { color: $strings } /* Literal.Date */
.m { color: $numbers} /* Literal.Number */
.s { color: $strings } /* Literal.String */
.na { color: $attributes } /* Name.Attribute */
.nb { color: $varAndProp } /* Name.Builtin */
.nc { color: $names } /* Name.Class */
.no { color: $keywords } /* Name.Constant */
.nd { color: #f1c829 } /* Name.Decorator */
.ni { color: $varAndProp } /* Name.Entity */
.ne { color: $names } /* Name.Exception */
.nf { color: $names } /* Name.Function */
.nl { color: $varAndProp } /* Name.Label */
.nn { color: $varAndProp } /* Name.Namespace */
.nx { color: $names } /* Name.Other */
.py { color: $varAndProp } /* Name.Property */
.nt { color: $tag } /* Name.Tag */
.nv { color: $varAndProp } /* Name.Variable */
.ow { color: $operators } /* Operator.Word */
.w { color: $varAndProp  } /* Text.Whitespace */
.mf { color: $numbers } /* Literal.Number.Float */
.mh { color: $numbers } /* Literal.Number.Hex */
.mi { color: $numbers } /* Literal.Number.Integer */
.mo { color: $numbers } /* Literal.Number.Oct */
.sb { color: $strings } /* Literal.String.Backtick */
.sc { color: $strings } /* Literal.String.Char */
.sd { color: $strings } /* Literal.String.Doc */
.s2 { color: $strings } /* Literal.String.Double */
.se { color: $numbers} /* Literal.String.Escape */
.sh { color: $strings } /* Literal.String.Heredoc */
.si { color: $strings } /* Literal.String.Interpol */
.sx { color: $strings } /* Literal.String.Other */
.sr { color: $strings } /* Literal.String.Regex */
.s1 { color: $strings } /* Literal.String.Single */
.ss { color: $strings } /* Literal.String.Symbol */
.bp { color: $varAndProp  } /* Name.Builtin.Pseudo */
.vc { color: $varAndProp } /* Name.Variable.Class */
.vg { color: $varAndProp } /* Name.Variable.Global */
.vi { color: $varAndProp } /* Name.Variable.Instance */
.il { color: $numbers} /* Literal.Number.Integer.Long */

.gh { } /* Generic Heading & Diff Header */
.gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
.gd { color: $operators; } /* Generic.Deleted & Diff Deleted */
.gi { color: $names; } /* Generic.Inserted & Diff Inserted */
.l-Scalar-Plain {color: $names}
